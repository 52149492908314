<template>
  <div v-loading="pageLoading" class="minWidth">
    <div style="min-width:1200px">
      <el-card>
        <div class = "flexRow">
          <div class = "stateBox" :class="{finish:detailMsg.IsFinish}">
            <span>{{detailMsg.IsFinish?'已结束':'进行中'}}</span>
          </div>
          <div class = "titleFont" style="margin-left:10px">{{detailMsg.ActivityName}}</div>
        </div>
        <div style="margin-left:30px">
          <div class = "minorFont" style="margin-top:15px">活动员工：
            <span style="margin-left:15px">{{detailMsg.JoinEmployeeCount}}位 
            <el-button type="text" style="margin-left:10px" @click="todel">查看详情</el-button></span>
          </div>
          <div class = "minorFont" style="margin-top:15px">活动时间：
            <span style="margin-left:15px">{{detailMsg.StartTime}} <span style="margin:0px 10px">至</span> {{detailMsg.EndTime}}</span>
          </div>
          <div class = "minorFont" style="margin-top:15px">活动权益：
            <span style="margin-left:15px">{{detailMsg.IsOpenActivityRights?(detailMsg.ActivityRightsType==1?'赠送优惠券':'赠送抽奖次数'):'无权益'}}</span>
          </div>
        </div>
      </el-card>
      <el-card style="margin-top:10px">
        <div class = "titleFont">数据概况</div>
        <div class = "flexRow" style="justify-content: space-between;margin:20px 0px">
          <div class = "flexRow">
            <div class="keyFont">添加时间：</div>
            <el-date-picker v-model="TimeValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
              value-format="yyyy-MM-dd" format="yyyy-MM-dd" :picker-options="pickerOptions" :clearable="false"></el-date-picker>
            <el-button type="primary" style="margin-left:10px" @click="getData">查询</el-button>
          </div>
          <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnj54UaMwBHOXIj10vSBQHWM" type="primary"
            :underline="false" target="_blank">查看数据统计说明  ></el-link>
        </div>
        <div class = "survey">
          <div class = "survey-demo" style="background:rgba(91,143,249,.2)">
            <div class = "demo-left" style="background: #5B8FF9;">
              <img src="@/assets/img/rosepowder2.png" style="width:24px;height:24px" alt="">
              <div class="demo-font">活动流量</div>
            </div>
            <div class="demo-right">
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.RiseFansTarget?detailMsg.RiseFansTarget:0}}</div>
                <div class="dataBox-bottom">目标涨粉人数</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.ActivityAddCount}}</div>
                <div class="dataBox-bottom">活动添加人数</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.RiseFansTarget?((detailMsg.ActivityAddCount/detailMsg.RiseFansTarget*100).toFixed(2)+'%'):'--'}}</div>
                <div class="dataBox-bottom">目标完成率</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.AddMemberCount}}</div>
                <div class="dataBox-bottom">活动新增客户数</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.DeleteMemberCount}}</div>
                <div class="dataBox-bottom">流失人数</div>
              </div>
            </div>
          </div>
          <div class = "survey-demo" style="background:rgba(66,178,150,.2)">
            <div class = "demo-left" style="background: #42B296;">
              <img src="@/assets/img/rosepowder1.png" style="width:24px;height:24px" alt="">
              <div class="demo-font">访问转化</div>
            </div>
            <div class="demo-right">
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.AskMemberCount}}</div>
                <div class="dataBox-bottom">商城访问人数</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.ActivityAddCount?((detailMsg.AskMemberCount/detailMsg.ActivityAddCount*100).toFixed(2)+'%'):'--'}}</div>
                <div class="dataBox-bottom">添加-访问转化率</div>
              </div>
            </div>
          </div>
          <div class = "survey-demo" style="background:rgba(240,206,70,.2)">
            <div class = "demo-left" style="background: #F0CE46;">
              <img src="@/assets/img/rosepowder3.png" style="width:19px;height:24px" alt="">
              <div class="demo-font">下单转化</div>
            </div>
            <div class="demo-right">
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.OrderMemberCount}}</div>
                <div class="dataBox-bottom">下单人数</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.OrderCount}}</div>
                <div class="dataBox-bottom">下单笔数</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.OrderMoney}}</div>
                <div class="dataBox-bottom">下单金额(元)</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.OrderMemberCount?(detailMsg.OrderMoney/detailMsg.OrderMemberCount).toFixed(2):0}}</div>
                <div class="dataBox-bottom">人均下单金额(元)</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.OrderMemberCount?(detailMsg.OrderCount/detailMsg.OrderMemberCount).toFixed(2):0}}</div>
                <div class="dataBox-bottom">人均下单笔数</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.ActivityAddCount?(detailMsg.OrderMemberCount/detailMsg.ActivityAddCount*100).toFixed(2)+'%':'--'}}</div>
                <div class="dataBox-bottom">添加-下单转化率</div>
              </div>
            </div>
          </div>
          <div class = "survey-demo" style="background:rgba(232,104,74,.2)">
            <div class = "demo-left" style="background: #E8684A">
              <img src="@/assets/img/rosepowder4.png" style="width:23px;height:24px" alt="">
              <div class="demo-font">成交转化</div>
            </div>
            <div class="demo-right">
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.PayMemberCount}}</div>
                <div class="dataBox-bottom">支付人数</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.PayOrderCount}}</div>
                <div class="dataBox-bottom">支付笔数</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.PayMoney}}</div>
                <div class="dataBox-bottom">支付金额(元)</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.PayMemberCount?(detailMsg.PayMoney/detailMsg.PayMemberCount).toFixed(2):0}}</div>
                <div class="dataBox-bottom">客单价(元)</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.PayOrderCount?(detailMsg.PayMoney/detailMsg.PayOrderCount).toFixed(2):0}}</div>
                <div class="dataBox-bottom">笔单价(元)</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.PayMemberCount?(detailMsg.PayOrderCount/detailMsg.PayMemberCount).toFixed(2):0}}</div>
                <div class="dataBox-bottom">人均支付笔数</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.ActivityAddCount?(detailMsg.PayMemberCount/detailMsg.ActivityAddCount*100).toFixed(2)+'%':'--'}}</div>
                <div class="dataBox-bottom">添加-支付转化率</div>
              </div>
              <!-- <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.ActivityAddCount?(detailMsg.AskMemberCount/detailMsg.ActivityAddCount*100).toFixed(2)+'%':'--'}}</div>
                <div class="dataBox-bottom">添加-访问转化率</div>
              </div> -->
              <div class="right-dataBox">
                <div class="dataBox-top">{{calculate(detailMsg.PayMemberCount,detailMsg.AskMemberCount)}}</div>
                <div class="dataBox-bottom">访问-支付转化率</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{calculate(detailMsg.PayMemberCount,detailMsg.OrderMemberCount)}}</div>
                <div class="dataBox-bottom">下单-支付转化率</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{getAverageCycle(detailMsg.TotalSeconds,detailMsg.ActivityAddCount)}}</div>
                <div class="dataBox-bottom">平均首单周期(天)</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.TotalOrderProfit}}</div>
                <div class="dataBox-bottom">订单总利润(元)</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.PayOrderCount?(detailMsg.TotalOrderProfit/detailMsg.PayOrderCount).toFixed(2):0}}</div>
                <div class="dataBox-bottom">订单平均利润(元)</div>
              </div>
              <div class="right-dataBox">
                <div class="dataBox-top">{{detailMsg.PayMemberCount?(detailMsg.TotalOrderProfit/detailMsg.PayMemberCount).toFixed(2):0}}</div>
                <div class="dataBox-bottom">人均订单利润(元)</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {
  qyWeixinRiseFansMarketingqyWeixinRiseFansMarketingData
}from "@/api/sv3.0.0"
export default {
  data () {
    return {
      pageLoading:false,
      detailMsg:{},
      TimeValue:[],
      pickerOptions:{
        disabledDate:(e)=>{
          return e.getTime()>new Date().getTime()-1000*60*60*24
          // return e.getTime()>new Date().getTime()
        },
        shortcuts:[
          {
            text: '昨天',
            onClick(picker) {
              const time = new Date(new Date().getTime()-1000*60*60*24)
              picker.$emit('pick', [time, time]);
            }
          },
          {
            text: '近7天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近15天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近30天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          },
        ]
      },

    }
  },
  created () {
    this.defaultTime()
    this.getData()
  },
  methods: {
    calculate(a,b){
      if(!a&&!b){
        return '--'
      }else if(!b){
        return '100%'
      }else{
        return (a/b).toFixed(2)*100 + '%'
      }
    },
    getAverageCycle(a,b){
      if(a==0||b==0){
        return '--'
      }else{
        let x = a/60/60/24/b
        if(x<1){
          return '<1天'
        }else{
          return (x).toFixed(2)+'天'
        }
      }
    },
    defaultTime(){
      let date = new Date(new Date().getTime()-1000*60*60*24)
      let year = date.getFullYear()
      let month = date.getMonth()+1>10?date.getMonth()+1:('0'+(date.getMonth()+1))
      let day = date.getDate()>10?date.getDate():('0'+date.getDate())
      let starttime = [year,month,day].join('-')
      let endtime = [year,month,day].join('-')
      this.TimeValue = [starttime,endtime]
    },
    nextDay(time){
      let date = new Date(new Date(time).getTime()+1000*60*60*24)
      let year = date.getFullYear()
      let month = date.getMonth()+1>10?date.getMonth()+1:('0'+(date.getMonth()+1))
      let day = date.getDate()>10?date.getDate():('0'+date.getDate())
      return [year,month,day].join('-')
    },
    async getData(){
      try{
        this.pageLoading = true
        let data = {
          QyWeixinRiseFansMarketingId:this.$route.query.Id,
          StartDate:this.TimeValue[0],
          EndDate:this.nextDay(this.TimeValue[1]),
        }
        let res = await qyWeixinRiseFansMarketingqyWeixinRiseFansMarketingData(data)
        if(res.IsSuccess){
          this.detailMsg = res.Result
        }
      }finally{
        this.pageLoading = false
      }
    },
    todel(){
      this.$router.push({
        path:'/Customer/rosepowder/detail',
        query:{
          Id:this.$route.query.Id,
        }
      })
    },
  }
}
</script>

<style lang = "less" scoped>
.minWidth{
  overflow: hidden;
  overflow-x: auto;
}
.flexRow{
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.stateBox{
  /* border:1px solid black; */
  background: #F0F9EB;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 5px 15px;
  border: 1px solid #C2E7B0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #67C23A;
  line-height: 1.5;
}
.finish{
  color: #606266;
  border: 1px solid #EBEEF5;
  background: #F8F8F9;
}
.titleFont{
  font-size: 22px;
  font-weight: 400;
  color: #303133;
  line-height: 1.5;
}
.minorFont{
  color: #606266;
  font-size: 14px;
  line-height: 1.5;
}
.keyFont{
  font-size: 14px;
  color: #303133;
  font-weight: bold;
  white-space: nowrap;
}
.survey{
  .survey-demo{
    display: flex;
    /* align-items: center; */
    margin-bottom: 20px;
    .demo-left{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding:20px 20px;
      flex-shrink: 0;
      .demo-font{
        font-size: 14px;
        color: white;
        line-height: 1.5;
        margin-top: 10px;
      }
    }
    .demo-right{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 15px 0px 15px 30px;
      .right-dataBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin:15px 0px;
        /* border: 1px solid black; */
        width: 240px;
        .dataBox-top{
          font-size: 22px;
          font-weight: bold;
          color: #303133;
          line-height: 1.5;
        }
        .dataBox-bottom{
          font-size: 14px;
          color: #606266;
          line-height: 1.5;
        }
      }
    }
  }
}
</style>